#root {
  position: absolute;
  inset: 0;
  display: flex;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px 8px 0 0;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.ql-container.ql-snow {
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
}
