@font-face {
  font-family: 'AntDesign';
  src: url('./AntDesign.ttf');
}
@font-face {
  font-family: 'Entypo';
  src: url('./Entypo.ttf');
}
@font-face {
  font-family: 'EvilIcons';
  src: url('./EvilIcons.ttf');
}
@font-face {
  font-family: 'Feather';
  src: url('./Feather.ttf');
}
/* @font-face {
  font-family: 'FontAwesome';
  src: url('./FontAwesome.ttf');
}
@font-face {
  font-family: 'FontAwesome5_Brands';
  src: url('./FontAwesome5_Brands.ttf');
}
@font-face {
  font-family: 'FontAwesome5_Regular';
  src: url('./FontAwesome5_Regular.ttf');
}
@font-face {
  font-family: 'FontAwesome5_Solid';
  src: url('./FontAwesome5_Solid.ttf');
}
@font-face {
  font-family: 'FontAwesome6_Brands';
  src: url('./FontAwesome6_Brands.ttf');
}
@font-face {
  font-family: 'FontAwesome6_Regular';
  src: url('./FontAwesome6_Regular.ttf');
}
@font-face {
  font-family: 'FontAwesome6_Solid';
  src: url('./FontAwesome6_Solid.ttf');
} */
@font-face {
  font-family: 'Fontisto';
  src: url('./Fontisto.ttf');
}
@font-face {
  font-family: 'Nunito-Black';
  src: url('../fonts/Nunito-Black.ttf');
}
@font-face {
  font-family: 'Nunito-BlackItalic';
  src: url('../fonts/Nunito-BlackItalic.ttf');
}
@font-face {
  font-family: 'Nunito-Bold';
  src: url('../fonts/Nunito-Bold.ttf');
}
@font-face {
  font-family: 'Nunito-BoldItalic';
  src: url('../fonts/Nunito-BoldItalic.ttf');
}
@font-face {
  font-family: 'Nunito-ExtraBold';
  src: url('../fonts/Nunito-ExtraBold.ttf');
}
@font-face {
  font-family: 'Nunito-ExtraBoldItalic';
  src: url('../fonts/Nunito-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Nunito-ExtraLight';
  src: url('../fonts/Nunito-ExtraLight.ttf');
}
@font-face {
  font-family: 'Nunito-ExtraLightItalic';
  src: url('../fonts/Nunito-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Nunito-Italic-VariableFont_wght';
  src: url('../fonts/Nunito-Italic-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Nunito-Italic';
  src: url('../fonts/Nunito-Italic.ttf');
}
@font-face {
  font-family: 'Nunito-Light';
  src: url('../fonts/Nunito-Light.ttf');
}
@font-face {
  font-family: 'Nunito-LightItalic';
  src: url('../fonts/Nunito-LightItalic.ttf');
}
@font-face {
  font-family: 'Nunito-Medium';
  src: url('../fonts/Nunito-Medium.ttf');
}
@font-face {
  font-family: 'Nunito-MediumItalic';
  src: url('../fonts/Nunito-MediumItalic.ttf');
}
@font-face {
  font-family: 'Nunito-Regular';
  src: url('../fonts/Nunito-Regular.ttf');
}
@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('../fonts/Nunito-SemiBold.ttf');
}
@font-face {
  font-family: 'Nunito-SemiBoldItalic';
  src: url('../fonts/Nunito-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Nunito-VariableFont_wght';
  src: url('../fonts/Nunito-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Foundation';
  src: url('./Foundation.ttf');
}
@font-face {
  font-family: 'Ionicons';
  src: url('./Ionicons.ttf');
}
@font-face {
  font-family: 'MaterialCommunityIcons';
  src: url('./MaterialCommunityIcons.ttf');
}
@font-face {
  font-family: 'MaterialIcons';
  src: url('./MaterialIcons.ttf');
}
@font-face {
  font-family: 'Octicons';
  src: url('./Octicons.ttf');
}
@font-face {
  font-family: 'SimpleLineIcons';
  src: url('./SimpleLineIcons.ttf');
}
@font-face {
  font-family: 'Zocial';
  src: url('./Zocial.ttf');
}
